import DOMPurify from 'dompurify';
import { marked } from 'marked';
import router from './router';
import RouteNames from './router/names';

const BLOCK_TAGS = ['blockquote', 'br', 'li', 'ol', 'p', 'ul'];
const HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const INLINE_TAGS = ['a', 'b', 'em', 'i', 'strong'];
const BASIC_FORMATTING_TAGS = [...BLOCK_TAGS, ...HEADING_TAGS, ...INLINE_TAGS];

export function markdownToHtml(text: string, { trusted = false, inline = false, basicFormatting = false } = {}) {
    let result = text;

    if (!trusted) {
        // Don't allow any user-entered HTML.
        result = DOMPurify.sanitize(result, { IN_PLACE: true, ALLOWED_TAGS: [] });
    }

    result = linkHashTags(result);

    const parseMarkdown = inline ? marked.parseInline : marked;
    result = parseMarkdown(result, { breaks: true });

    if (trusted) {
        result = enableLinkTargetBlank(result);
    }

    if (!trusted) {
        // Sanitize the generated markup.
        const options = { IN_PLACE: true };

        if (basicFormatting) {
            Object.assign(options, {
                ALLOWED_TAGS: BASIC_FORMATTING_TAGS,
            });
        }

        result = DOMPurify.sanitize(result, options);
    }

    return result;

}

function linkHashTags(string: string) {
    return string.replace(/#([\w-]+)/g, (match, text) => {
        const search = text.replace(/[_-]+/g, ' ');
        const route = router.resolve({ name: RouteNames.POSTS, query: { search } });
        return `<a href="${route.href}">${match}</a>`;
    });
}

function enableLinkTargetBlank(text: string) {
    // Lead the URL with a caret, e.g.:
    // [Open me in a new window](^https://www.example.com/)
    // Note that carets are encoded as "%5E".
    return text.replace(/href=(?<quote>["'])%5E/g, 'target="_blank" href=$<quote>');
}
