
import Vue from '@/vueTyped';

// This is mostly taken from:
// https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/

export default Vue.extend({
    props: {
        value: {
            type: undefined,
            required: true,
        },
    },

    data() {
        return {
            interval: -1,
        };
    },

    methods: {
        forceRepaint(element: HTMLElement) {
            getComputedStyle(element).height;
        },

        enter(element: HTMLElement) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = '';
            element.style.position = '';
            element.style.visibility = '';
            element.style.height = '0';

            this.forceRepaint(element);

            requestAnimationFrame(() => {
                this.startEmittingSizes(element);
                element.style.height = height;
            });
        },

        afterEnter(element: HTMLElement) {
            this.stopEmittingSizes();
            element.style.height = 'auto';
            this.forceRepaint(element);
            this.emitSize(element);
        },

        leave(element: HTMLElement) {
            const height = getComputedStyle(element).height;

            element.style.height = height;

            this.forceRepaint(element);

            requestAnimationFrame(() => {
                this.startEmittingSizes(element);
                element.style.height = '0';
            });
        },

        afterLeave(element: HTMLElement) {
            this.stopEmittingSizes();
            this.forceRepaint(element);
            this.emitSize(element);
        },

        startEmittingSizes(element: HTMLElement) {
            if (this.interval === -1) {
                this.interval = setInterval(() => this.emitSize(element), 1000 / 60) as unknown as number;
            }
        },

        stopEmittingSizes() {
            if (this.interval !== -1) {
                clearInterval(this.interval);
                this.interval = -1;
            }
        },

        emitSize(element: HTMLElement) {
            this.$emit('resize', element.offsetHeight);
            window.dispatchEvent(new Event('resize'));
        },
    },
});
