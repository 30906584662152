
import Vue from '@/vueTyped';
import { markdownToHtml } from '../util.markdown';

export default Vue.extend({
    props: {
        tag: {
            type: String,
            default : 'div'
        },

        value: {
            type: String,
            default: ''
        },

        inline: {
            type: Boolean,
            default: false,
        },

        trusted: {
            type: Boolean,
            default: false,
        },

        basicFormatting: {
            type: Boolean,
            default: false,
        },

        containMargins: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        html(): string {
            const { trusted, inline, basicFormatting } = this;
            return markdownToHtml(this.value, { trusted, inline, basicFormatting });
        },
    },
});
