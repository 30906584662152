import Vue, { VueConstructor } from 'vue'
import { Store, Dispatch, DispatchOptions } from 'vuex';
import { RootState } from '@/types'
import { VuexActionResult } from '@/store/util';

interface TypedDispatch extends Dispatch {
    (type: string, payload?: any, options?: DispatchOptions): Promise<VuexActionResult>;
}

interface TypedStore<T> extends Store<T> {
    dispatch: TypedDispatch;
}

abstract class VueTypedClass extends Vue {
    public $store!: TypedStore<RootState>
}
const VueTyped = Vue as VueConstructor<VueTypedClass>;

export default VueTyped;
