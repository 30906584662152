
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'LoadingIndicator',
    components: { },
    data() {
    return {};
    },
    async created() {},
});
